.subject-areas .ui.secondary.menu {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
}
.subject-areas .ui.secondary.menu .item{
    border: 1px solid #ccc;
    margin: 8px; 
    box-sizing: border-box;
}

.subject-areas .ui.secondary.menu .active.item{
    background-color: #002244;
    color: white
}

.subject-areas .ui.secondary.menu .active.item:hover{
    background-color: #224466;
    color: white
}

